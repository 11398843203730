function slidersSmall() {
    var sliderS = $('.sliders-small');
    if ($(window).width() < 992) {
        sliderS.addClass('owl-carousel');
        sliderS.owlCarousel({
            navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
            items: 1,
            loop: true,
            nav: true,
            dots: false,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1
                },
                768: {
                    items: 2
                }
            }
        });
    } else {
        sliderS.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
        sliderS.find('.owl-stage-outer').children().unwrap();
    }
};

function sliderMd() {
    var slider = $('.slider'),
        dot = slider.attr('data-dot') && slider.attr('data-dot') == "yes" ? true : false;

    if(slider.hasClass('owl-carousel')){
      slider.trigger('destroy.owl.carousel').removeClass('owl-carousel owl-loaded');
      slider.find('.owl-stage-outer').children().unwrap();
      slider.addClass('aa');
    }
    slider.addClass('owl-carousel');
    slider.owlCarousel({
        navText: ["<i class='fa fa-angle-left'></i>", "<i class='fa fa-angle-right'></i>"],
        items: 1,
        loop: false,
        nav: true,
        dots: dot,
        autoplay: true,
    });
};

//MATCH HEIGHT
function setMH() {
    $('.item').find('h4').matchHeight();
    $('.item').find('p').matchHeight();
    $('.office-item').matchHeight();
    $('.testimoni').find('.sliders-small').find('.col-md-3').matchHeight();
    $('.upgrade.has-more').find('.item').matchHeight();
    // $('dl').find('dt,dd').matchHeight();
    $('.form-get-quote').find('dt,dd').matchHeight();
    $('.same-h').find('.set-h').matchHeight({
        byRow: false
    });
    if($('.list-col').length > 0){
        setTimeout($('.list-col').find('.item').matchHeight(), 1000);
    }
}

//STICKY FOOTER
function stickyFooter() {
    var m = $('main'),
        h = $('header').outerHeight(),
        f = $('footer').outerHeight(),
        w = $(window).height(),
        set = w - h - f;
    m.css('min-height', set)
    // console.log('main('+ set +') = window(' + w + ') - header(' + h + ') - footer(' + f + ')')
}

//selectbox with tag
function multiselect() {
    //remove Array
    Array.prototype.remove = function() {
        var what, a = arguments,
            L = a.length,
            ax;
        while (L && this.length) {
            what = a[--L];
            while ((ax = this.indexOf(what)) !== -1) {
                this.splice(ax, 1);
            }
        }
        return this;
    };

    $('select.select.has-tag:not(.scripted)').each(function(index) {
        var t = $(this),
            bs = t.closest('.bootstrap-select'),
            pr = t.closest('.form-group'),
            bn = pr.find('.box-tag').length,
            boxTag = bn == 0 ? $('<div class="box-tag"></div>').insertAfter(bs) : pr.find('.box-tag'),
            bm = $("<div class='text'>").insertAfter(boxTag),
            sel = t.find('option:selected'),
            b = index;
        t.addClass('scripted');


        //get last selected value in multiple select
        var allSelected = [],
            previouslySelected = [];
        if (sel.length > 0) {
            allSelected = $(this).val();
            previouslySelected = $(this).val();
        }

        function getLastSelected(val) {

            if (val != null) {
                var newSelections = val.filter(function(element) {
                    return previouslySelected.indexOf(element) == -1;
                });
                previouslySelected = val;

                if (newSelections.length) {
                    // If there are multiple new selections, we'll take the last in the list
                    return newSelections.reverse()[0];
                } else {
                    // console.log('rem =' + newSelections);
                    return "rem";

                }
            } else {
                return val;
            }
        }

        function removeTag(v) {
            var val = v == null ? 'none' : v;
            boxTag.find('.tag').each(function() {
                var value = $(this).data('value');
                if (!val.includes(value)) {
                    $(this).remove();
                }
            })
        }

        function insertTag(val, text) {
            boxTag.append('<span class="tag" data-value="' + val + '">' + text + '<i class="remove fa fa-times" data-toggle="tooltip" data-placement="top" title="Remove"></i></span>');
            $('[data-toggle="tooltip"]').tooltip({
                container: "body"
            });
        }

        function insertOther(val) {
            boxTag.append('<span class="tag" data-value="' + val + '"><input type="text" name="other" placeholder="Type your cargo" required><i class="remove fa fa-times" data-toggle="tooltip" data-placement="top" title="Remove"></i></span>');
            $('[data-toggle="tooltip"]').tooltip({
                container: "body"
            });
        }


        $(this).change(function() {
            var selected = $(this).find('option[value='+lastAdded+']'),
                text = selected.html(),
                lastAdded = getLastSelected($(this).val()),
                opt = $(this).find('option[value="' + lastAdded + '"]').html();
            //bm.html("value-="+$(this).val()+"<br>lastAdded = "+lastAdded+"<br>allSelected = "+allSelected+"<br>previouslySelected ="+previouslySelected);
            // console.log('last =' + lastAdded + " - " + $(this).val());

            // $(this).find('option[value='+lastAdded+']').css('background', 'red');
            if (lastAdded == 0) {
                $(this).selectpicker('deselectAll');
                $(this).selectpicker('val', "0");
                insertTag(lastAdded, opt);
                allSelected = ["0"];
                previouslySelected = ["0"];
                $(this).prop('disabled', true).closest('.bootstrap-select').addClass('disabled');
            } else if (lastAdded == 'Other') {
                // console.log('Other');
                var isAdded = lastAdded == "rem" ? true : allSelected.includes(lastAdded.toString());
                allSelected = $(this).val() == null ? [] : $(this).val();
                if (!isAdded) {
                    insertOther(lastAdded);
                } else {
                    removeTag($(this).val());
                    allSelected.remove($(this).val());
                }
            } else if (lastAdded != null) {
                var isAdded = lastAdded == "rem" ? true : allSelected.includes(lastAdded.toString());

                allSelected = $(this).val() == null ? [] : $(this).val();

                if (!isAdded) {
                    insertTag(lastAdded, opt);
                } else {
                    removeTag($(this).val());
                    allSelected.remove($(this).val());
                }
            } else {
                removeTag($(this).val());
                allSelected = [];
            }
            t.selectpicker('toggle');
        });

        // $('body').on('click', '.tag .remove', function() {
        //     var val = $(this).parent().data('value').toString(),
        //         bselect = $(this).closest('.box-tag').prev('.bootstrap-select'),
        //         select = bselect.find('select.has-tag');
        //     $(this).parent().remove();
        //     $('.tooltip').remove();
        //     allSelected.remove(val);
        //     previouslySelected.remove(val);
        //     if (bselect.hasClass('disabled')) {
        //         bselect.removeClass('disabled').find('select').prop('disabled', false);
        //         select.selectpicker('deselectAll');
        //         allSelected = [];
        //         previouslySelected = [];
        //         console.log('a');
        //     } else {
        //         console.log('b');
        //         select.selectpicker('val', allSelected);
        //     }
        // });
        $('.box-tag').each(function(){
            var th = $(this);
            th.on('click', '.remove', function(){
                var tp = $(this).parent(),
                val = tp.data('value'),
                bselect = th.prev('.bootstrap-select'),
                selects = bselect.find('select.has-tag');
                $('.tooltip').remove();
                tp.remove();
            bs.css('background', 'red');
            if(t.val() != null || t.val() != ""){
                // select.find('.select.hastag option[value='+val+']').prop('selected', false);
                // selects.selectpicker('deselectAll');
                allSelected.remove(val);
                var so = selects.find('option'),
                    sv = so.text();
                    so.each(function() {
                        if($(this).val() == val){
                            $(this).prop('selected', false)
                        }
                    })
                    // so.prop('selected', false);
                    selects.selectpicker('refresh');
            }

            selects.selectpicker('refresh');
        })
    })
    })
}

function calcAmount() {
    var sum = 0;
    var tax = $('input[name=tax]');
    var subtotal = $('input[name=sub_total]');
    var taxcharge = $('input[name=tax_charges]');
    var total = $('input[name=total]');

    $('#amount').find('.currency').each(function() {
        if ($(this).val() !== '') {
            sum += parseInt(this.value.split('.').join(''));
        }
    });

    sum = Math.round(sum);
    subtotal.val(sum);
    subtotal.rupiah();

    if (subtotal.val() !== '' && tax.val() !== '') {
        var sval = parseInt(subtotal.val().split('.').join(''));
        var tval = parseInt(tax.val().split('.').join(''));
        var tcHasil = (sval / 100) * tval;

        var tcHasil = Math.round(tcHasil);
        taxcharge.val(tcHasil);
        taxcharge.rupiah();
    }
    if (subtotal.val() !== '' && taxcharge.val() !== '') {
        var sval = parseInt(subtotal.val().split('.').join(''));
        var cval = parseInt(taxcharge.val().split('.').join(''));

        var totHasil = sval + cval;
        var totHasil = Math.round(totHasil);
        total.val(totHasil);
        total.rupiah();
    }
}


// VIEW QUOTE
function viewQuote() {
    $('.form-view-quote').each(function() {
        var form = $(this).find('form');
        // form.validate({
        //     ignore: ":disabled, :hidden",
        //     errorPlacement: function(error, element) {
        //         var fg = $(element).closest('.valthis');
        //         error.appendTo(fg);
        //     }
        // });

        $('#amount').find('.currency').each(function() {
            $(this).keyup(function() {
                calcAmount();
            });
        });

        $('input[name=tax]').keyup(function() {
            calcAmount();
        });

        $('.multiplier').find('.btn-delete').each(function() {
            $(this).click(function() {
                setTimeout(calcAmount, 1000)
            });
        });
    });
}

// MULTIPLIER
function multiplierWorHours() {
    $('.multi-working-h').each(function() {
        var t = $(this),
            limit = t.data('limit'),
            item = t.find('.sample')
            add = t.find('.btn-add'),
            target = t.find('.box-item');
        // format;

        item.find('select').each(function() {
            $(this).selectpicker('destroy');
        });

        format = item.clone().removeClass('sample');
        bind(item);

        function bind(obj) {
            $('select.select').each(function() {
                $(this).selectpicker({
                    style: 'select-control',
                    size: 5
                });
                var du = $(this).data('unit'),
                    btn = $(this).closest('.bootstrap-select').find('button');

                if (du != undefined) {
                    btn.addClass('has-unit');
                    btn.append('<span class="unit">' + du + '</spa>');
                }
            });
            $(".num-only").keypress(function(e) {
                if (e.which != 8 && e.which != 0 && e.which != 43 && (e.which < 48 || e.which > 57)) {
                    return false;
                }
            });
            viewQuote();
            $('.currency').each(function() {
                $(this).keyup(function() {
                    $(this).rupiah();
                })
            });
        };

        function reOrder(obj) {
            var itm = obj.find('.item');
            itm.each(function(i) {
                var tmp = i + 1;
                //re order name
                $(this).find('input').each(function() {
                    if ($(this).attr('name')) {
                        var name = $(this).attr('name').replace(/\_\d/g, '_' + (i));
                        $(this).attr('name', name);
                    }
                });
                $(this).find('select').each(function() {
                    if ($(this).attr('name')) {
                        var name = $(this).attr('name').replace(/\_\d/g, '_' + (i));
                        $(this).attr('name', name);
                    }
                });
            });
            //show and hide delete button
            if (itm.length > 1) {
                t.addClass('cloned');
                $('.show-next').prop('disabled', false);
                // itm.eq(0).find('.show-next').attr('disabled','disabled');
            } else {
                t.removeClass('cloned');
            };
            // show and hide add button
            if (itm.length >= limit) {
                t.addClass('limited');
            } else {
                t.removeClass('limited');
            };
            // set item length
            $('#whCount').val(itm.length - 1);
        };

        add.click(function(e) {
            e.preventDefault();
            var html = format.clone();
            target.append(html);
            bind(html);
            html.slideDown(300);
            reOrder(target);
        });

        // DELETE
        $('body').on('click', '.btn-delete', function(e) {
            e.preventDefault();
            var parent = $(this).closest('.item'),
                target = parent.closest('.box-item');

            parent.slideUp(300, function() {
                parent.remove();
                reOrder(target);
            });
        });
    });
}

function multiplier() {
    $('.multiplier').each(function() {
        var t = $(this),
            limit = t.data('limit'),
            item = t.find('.item').eq(0),
            add = t.find('.btn-add'),
            target = t.find('.box-item'),
            format;

        item.find('select').each(function() {
            $(this).selectpicker('destroy');
        });

        format = item.clone().hide();
        bind(item);

        function bind(obj) {
            $('select.select').each(function() {
                $(this).selectpicker({
                    style: 'select-control',
                    size: 5
                });
                var du = $(this).data('unit'),
                    btn = $(this).closest('.bootstrap-select').find('button');

                if (du != undefined) {
                    btn.addClass('has-unit');
                    btn.append('<span class="unit">' + du + '</spa>');
                }
            });
            $(".num-only").keypress(function(e) {
                if (e.which != 8 && e.which != 0 && e.which != 43 && (e.which < 48 || e.which > 57)) {
                    return false;
                }
            });
            viewQuote();
            $('.currency').each(function() {
                $(this).keyup(function() {
                    $(this).rupiah();
                })
            });
        };

        function reOrder(obj) {
            var itm = obj.find('.item');
            itm.each(function(i) {
                var tmp = i + 1;
                //re order name
                $(this).find('input').each(function() {
                    if ($(this).attr('name')) {
                        var name = $(this).attr('name').replace(/\_\d/g, '_' + (i));
                        $(this).attr('name', name);
                    }
                });
            });
            //show and hide delete button
            if (itm.length > 1) {
                t.addClass('cloned');
                $('.show-next').prop('disabled', false);
                // itm.eq(0).find('.show-next').attr('disabled','disabled');
            } else {
                t.removeClass('cloned');
            };
            // show and hide add button
            if (itm.length >= limit) {
                t.addClass('limited');
            } else {
                t.removeClass('limited');
            };
            // set item length
            $('input[name=amountCount]').val(itm.length);
        };

        add.click(function(e) {
            e.preventDefault();
            var html = format.clone();
            target.append(html);
            bind(html);
            html.slideDown(300);
            reOrder(target);
        });

        // DELETE
        $('body').on('click', '.btn-delete', function(e) {
            e.preventDefault();
            var parent = $(this).closest('.item'),
                target = parent.closest('.box-item');

            parent.slideUp(300, function() {
                parent.remove();
                reOrder(target);
            });
        });
    });
}

// STICKY SIDEBAR
function setStyle(element, cssProperty) {
    for (var property in cssProperty) {
        element.style[property] = cssProperty[property];
    }
}

function destroySticky(element) {
    setStyle(element, {
        top: '',
        left: '',
        bottom: '',
        width: '',
        position: ''
    });
}

function getOffset(el) {
    el = el.getBoundingClientRect();
    return {
        left: el.left + window.scrollX,
        top: el.top + window.scrollY
    }
}

// Sticky Sidebar
function simpleStickySidebar(element, options) {
    if($('.stickyme-inner').length > 0){
        // Global options
        var sticky = document.querySelector(element); // Sticky sidebar
        var container = document.querySelector(options.container); // Sticky sidebar container
        var topSpace = options.topSpace ? options.topSpace : 0; // Top spacing after sticky
        var bottomSpace = options.bottomSpace ? options.bottomSpace : 0; // Bottom spacing after sticky

        // vars
        var $window = window; // window
        var stickyHeight = sticky.getBoundingClientRect().height; // Sticky sidebar height
        var stickyOffsetTop = getOffset(sticky).top; // Sticky sidebar top offset
        var stickyOffsetLeft = getOffset(sticky).left; // Sticky sidebar left offset
        var topFixed = false; // checkpoint
        var bottomFixed = false; // checkpoint
        var lastScrollVal = 0; // checkpoint


        //customvars
        var hAds = $('.a-banner.a-banner-bottom').outerHeight();
        var stopper = $('.stickyme-stopper');

        var stopperOffsetBottom = stopper.offset().top - $('.stickyme-inner').outerHeight() - 50;
        var par = $('.stickyme').closest('.row');
        var inner = $('.stickyme-inner');

        if($('.a-banner.a-banner-bottom').length>0){
            var stopTop = par.height() - inner.height() - 150;
        }else{
            var stopTop = par.height() - inner.height();
        }
        console.log(stopperOffsetBottom);
        // par.css('min-height',inner.height() + 80); // original
        par.css('min-height',inner.height() + 100);

        $(window).on('scroll', function(){
            var th = $('.sidebar').height();
            $('.col-right-wrap').css('min-height', th);
        });

        var upvar = par.height();

        // console.log("par:"+par.height()+", inner:"+inner.height()+", stopTop:"+stopTop);

        // scrolling
        if(upvar > inner.height() + 100) {
            inner.removeClass('no-sticky');
            window.addEventListener('scroll', function(event) {
                var scrollTop = window.scrollY;
                // when scroll position touch the "Sidebar"
                if (scrollTop > stickyOffsetTop - topSpace) {
                    // if "Sidebar" smaller than viewport
                    if (stickyHeight <= $window.innerHeight - topSpace) {
                        // fix "Sidebar" from top
                        setStyle(sticky, {
                            top: topSpace + "px",
                            left: stickyOffsetLeft + "px",
                            bottom: '',
                            width: sticky.getBoundingClientRect().width + "px",
                            position: 'fixed'
                        });
                        if (scrollTop > stopperOffsetBottom){
                                setStyle(sticky, {
                                    top: stopTop + "px",
                                    left: '',
                                    bottom: '',
                                    width: '',
                                    position: 'absolute'
                                });
                            }
                    } else {
                        // scrolling down
                        if (scrollTop > lastScrollVal) {
                            // if "Sidebar" fixed from top during scrolling down
                            if (topFixed) {
                                // get new offset of "Sidebar"
                                var absoluteStickyOffsetTop = getOffset(sticky).top;

                                setStyle(sticky, {
                                    top: absoluteStickyOffsetTop - getOffset(container).top + "px",
                                    left: '',
                                    bottom: '',
                                    width: '',
                                    position: 'absolute'
                                });
                                topFixed = false;
                            }
                            // make "Sidebar" fixed from bottom when bottom area visible in viewport
                            if (scrollTop > stopperOffsetBottom - $window.innerHeight) {
                                setStyle(sticky, {
                                    top: '',
                                    left: stickyOffsetLeft + "px",
                                    bottom: bottomSpace + "px",
                                    width: sticky.getBoundingClientRect().width + "px",
                                    position: 'fixed'
                                });
                                bottomFixed = true;
                            }
                            if (scrollTop > stopperOffsetBottom){
                                setStyle(sticky, {
                                    top: stopTop + "px",
                                    left: '',
                                    bottom: '',
                                    width: '',
                                    position: 'absolute'
                                });
                            }
                        } else { // scrolling up
                            // get new offset of "Sidebar" during scrolling up
                            var absoluteStickyOffsetTop = getOffset(sticky).top;
                            // console.log(absoluteStickyOffsetTop);
                            //  if "Sidebar" fixed from bottom, stop sticky to its position
                            if (bottomFixed) {
                                setStyle(sticky, {
                                    top: absoluteStickyOffsetTop - getOffset(container).top + "px",
                                    left: '',
                                    bottom: '',
                                    width: '',
                                    position: 'absolute'
                                });
                                bottomFixed = false;
                            }
                            // make "Sidebar" fixed from top when top area visible in viewport
                            if (scrollTop < absoluteStickyOffsetTop - topSpace) {
                                setStyle(sticky, {
                                    top: topSpace + "px",
                                    left: stickyOffsetLeft + "px",
                                    bottom: '',
                                    width: sticky.getBoundingClientRect().width + "px",
                                    position: 'fixed'
                                });
                                topFixed = true;
                            }
                        }
                        lastScrollVal = scrollTop;
                    }
                } else {
                    // make sidebar to default position
                    destroySticky(sticky);
                }
            });
        } else {
            // destroySticky(sticky);
            inner.addClass('no-sticky');
            // console.log('destroyed');
        }
    }
}

// Content height


//Alert Image
function alertUpload(par) {
    var text = par;
    $('body').append('<div class="alert-upload blink"><div class="alert alert-danger"><i class="close fa fa-times" data-dismiss="alert"></i>' + text + '</div></div>');
    $('.alert-upload').fadeIn(300);

    $(function() {
        setTimeout(function() {
            $('.alert-upload').fadeOut(300);
        }, 3000);
    });
    $(function() {
        setTimeout(function() {
            $('.alert-upload').remove();
        }, 4500);
    });
};

// Blinker

$('.help-block').each(function(){
    var t = $(this),
        ico = t.find('.fa');
    ico.addClass('blinker');

    ico.mouseenter(function() {
        $(this).removeClass('blinker');
    }).mouseleave(function() {
        $(this).addClass('blinker');
    });
});


$(document).ready(function() {

    if($('.stickyme').length != 0){
        $('.stickyme').each(function(){
            simpleStickySidebar('.stickyme-inner', {
                container: '.stickyme',
                topSpace: 70,
                bottomSpace : 100
            });
            setTimeout(function() {
                simpleStickySidebar(".stickyme-inner", {
                    container: ".stickyme",
                    topSpace: 70,
                    bottomSpace: 100
                })
            }, 1000);
            setTimeout(function() {
                simpleStickySidebar(".stickyme-inner", {
                    container: ".stickyme",
                    topSpace: 70,
                    bottomSpace: 100
                })
            }, 2000);
            // setTimeout(function() {
            //     simpleStickySidebar(".stickyme-inner", {
            //         container: ".stickyme",
            //         topSpace: 70,
            //         bottomSpace: 100
            //     })
            // }, 3000)
            // setTimeout(function() {
            //     simpleStickySidebar(".stickyme-inner", {
            //         container: ".stickyme",
            //         topSpace: 70,
            //         bottomSpace: 100
            //     })
            // }, 3400)
        })
    }


    $('a').each(function(){
        $(this).click(function(){
            simpleStickySidebar('.stickyme-inner', {
                container: '.stickyme',
                topSpace: 70,
                bottomSpace : 100
            });
        })
    });

    $('.collapse').each(function(){
        var i = $('.stickyme-inner'),
            oi = $('.company-info'),
            th = $(this).closest('.sidebar').height();
        $(this).on('click', function () {
            if($(this).hasClass('in')){
                i.addClass('no-sticky');
                setTimeout(function() {
                    simpleStickySidebar(".stickyme-inner", {
                        container: ".stickyme",
                        topSpace: 70,
                        bottomSpace: 100
                    })
                }, 1300);
                if(i.length > 0){
                    $("html, body").animate({
                        scrollTop: i.offset().top
                    }, 'slow');
                }
            }else{
                $(this).closest('.row').css('min-height', th);
                setTimeout(function() {
                    simpleStickySidebar(".stickyme-inner", {
                        container: ".stickyme",
                        topSpace: 70,
                        bottomSpace: 100
                    })
                }, 1500);
                i.addClass('no-sticky');
            }
        })
    })

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $('.stickyme').each(function(){
            setTimeout(function() {
                simpleStickySidebar('.stickyme-inner', {
                    container: '.stickyme',
                    topSpace: 70,
                    bottomSpace : 100
                });
            }, 1000);
        })
    });

    $('.page-numbers').click(function(){
        $('.stickyme').each(function(){
            setTimeout(function() {
                simpleStickySidebar('.stickyme-inner', {
                    container: '.stickyme',
                    topSpace: 70,
                    bottomSpace : 100
                });
            }, 2000);
        })
    })

    $('.popover-chat_icon').each(function(){
        var t = $(this);
        t.click(function(){
            $('.popover-chat_box').toggleClass("show");
        })
    })

    // $('.page-numbers').each(function(){
    //     var t = $(this),
    //         li = t.find('li');
    //     li.each(function(){
    //         $(this).click(function(){
    //             alert('a');
    //             simpleStickySidebar('.stickyme-inner', {
    //                 container: '.stickyme',
    //                 topSpace: 70,
    //                 bottomSpace : 100
    //             });
    //         })
    //     })
    // });


    // Replace all SVG images with inline SVG
    jQuery('img.svg').each(function() {
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass + ' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });


    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();

    //SELECTPICKER
    $('select.select').each(function() {
        $(this).selectpicker({
            style: 'select-control',
            size: 5
        });
        var du = $(this).data('unit'),
            btn = $(this).closest('.bootstrap-select').find('button');

        if (du != undefined) {
            btn.addClass('has-unit');
            btn.append('<span class="unit">' + du + '</spa>');
        }
    });

    //inputfile
    $('.upload-file').each(function(e) {
        var t = $(this),
            input = t.find('.inputfile'),
            label = t.find('.label-btn'),
            del = t.find('.del-btn'),
            info = t.find('.file-info'),
            prev = t.find('.image-preview'),
            pB = t.find('.pB'),
            to = t.closest('form').attr('action'),
            fSize;

        function toggleDel() {
            if (t.hasClass('has-file')) {
                del.removeClass('dis');
            } else {
                del.addClass('dis');
            }
        }
        toggleDel();

        function readURL(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function(e) {
                    lb();
                    setTimeout(
                    function()
                    {
                        prev.css('background-image', 'url(' + e.target.result + ')');
                    }, 1000);
                    // console.log('hai');
                }
                reader.readAsDataURL(input.files[0]);
            }
        }

        function lb(){
            var ajax = new XMLHttpRequest();
            var formdata = new FormData();
            // ajax.upload.addEventListener('progress', uploadProgress, false);
            ajax.onprogress = function (e) {
                if (e.lengthComputable) {
                    // console.log(e.loaded+  " / " + e.total);
                    var percent = (e.loaded / e.total) * 100;
                    pB.attr('value', Math.round(percent));
                }
            }

            ajax.onloadstart = function (e) {
                pB.removeClass('hide');
                console.log(to);
            }
            ajax.onloadend = function (e) {
                pB.addClass('hide');
            }
            ajax.open("POST", to);
            ajax.send(FormData);

        }

        input.change(function(e) {
            var fileName = '',
                val = $(this).val();

            if (this.files && this.files.length > 1) {
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            } else if (e.target.value) {
                fileName = e.target.value.split('\\').pop();
            }

            if (this.files[0].size > 200097152) {
                // alert('Max upload 2MB.');
                alertUpload('Your file is too large!');
                // input.val('');
            } else {

                if (fileName && prev.length == 0) {
                    switch (val.substring(val.lastIndexOf('.') + 1).toLowerCase()) {
                        case 'doc':
                        case 'docx':
                        case 'pdf':
                        case 'txt':
                        case 'jpg':
                        case 'png':
                            info.html(fileName);
                            info.removeClass('deleted');
                            t.addClass('has-file');
                            break;
                        default:
                            // alert('Only document files are allowed.')
                            alertUpload('Only document files are allowed.');
                            break;
                    }
                }

                if (prev.length != 0) {
                    switch (val.substring(val.lastIndexOf('.') + 1).toLowerCase()) {
                        case 'gif':
                        case 'jpg':
                        case 'png':
                        case 'svg':
                            readURL(this);
                            t.addClass('has-file');
                            break;
                        default:
                            // alert('Only image files are allowed.')
                            alertUpload('Only image files are allowed.');
                            break;
                    }
                }

            }

            toggleDel();
        });

        del.click(function() {
            if (prev.length != 0) {
                prev.css('background-image', '');
            }

            info.addClass('deleted');
            input.val('');
            t.removeClass('has-file');

            pB.addClass('hide');
            pB.attr('value', 0)

            toggleDel();
        })
    });


    //IMAGESLOADED
    $('body').imagesLoaded()
        .done(function() {
            $('#preloading').fadeOut('slow');
        })
        .fail(function() {
            $('#preloading').fadeOut('slow');
        })



    $('button[type="reset"]').each(function() {
        var t = $(this),
            f = $(this).closest('form'),
            s = $(this).closest('form').find('select.select');

        t.click(function() {
            s.val('default');
            s.selectpicker('refresh');
        })
    });


    //TOGGLE MENU
    $('.icon-menu').click(function() {
        $('body').toggleClass('menu-open');
    });
    $(".m-toggle").find('.trigger').click(function() {
        $('body').toggleClass('m-toggle-open');
    }).find('.sidebar').click(function(e) {
        // e.stopPropagation();
    });
    $('.foot-menu').each(function() {
        var t = $(this),
            ft = t.find('.foot-title'),
            ul = t.find('ul');
        ft.click(function() {
            t.toggleClass('open');
        })
    });

    // TOGGLE FILTER
    $('.sort-filter').each(function(){
        $(this).find('.close').click(function(){
            $('body').removeClass('sort-open');
            $('body').removeClass('filter-open');
        })
        $(this).find('.trigger .col-sort').click(function(){
            $('body').addClass('sort-open');
        });
        $(this).find('.trigger .col-filter').click(function(){
            $('body').addClass('filter-open');
        });
    })


    //STICKY HEADER
    $(window).scroll(function() {
        var sticky = $('header'),
            scroll = $(window).scrollTop(),
            h = $(window).height(),
            btt = $('.back-to-top');

        if (scroll > 0) {
            sticky.addClass('fixed');
            if (scroll > h) {
                btt.addClass('on');
            }
        } else {
            sticky.removeClass('fixed');
            btt.removeClass('on');
        }
    });


    //BACK TO TOP
    // var html = "<a class='back-to-top h'><i class='fa fa-angle-up'></i></a>";
    // $('body').append(html);
    // $('.back-to-top').on('click', function() {
    //   $("html, body").animate({
    //     scrollTop: 0
    //   }, 'slow');
    // });

    $('.form-search').on('submit', function() {
        $("html, body").animate({
            scrollTop: 0
        }, 'slow');
    });

    //ON WINDOW RESIZE
    $(window).resize(function() {
        stickyFooter();
        slidersSmall();
        setMH();
    })

    //rating
    // $('.box-rate').each(function() {
    //     var t = $(this),
    //         r = t.find('.rate'),
    //         s = t.find('.star');

    //     if (s.width() == 0) {
    //         r.html('unrated');
    //     }
    // })

    //table
    $('.quote-request').each(function() {
        var t = $(this),
            act = t.find('.table-action'),
            check = t.find('.checkbox').find('input[type="checkbox"]'),
            cAll = t.find('#checkAll');

        function toggle() {
            var n = t.find('input:checkbox:checked').length;
            if (n == 0) {
                act.removeClass('shown');
            } else {
                act.addClass('shown');
            }
        }

        cAll.click(function() {
            check.not(this).prop('checked', this.checked);
        })

        check.change(function() {
            toggle();
        })
    })

    //STEP WIZZARD
    $('.get-quote').each(function() {

        var stepWizz = $(this).find('.step-wizz'),
            step = stepWizz.find('.step'),
            stepNum = step.find('.step-num'),
            n = 0,
            stepAct = $('.form-get-quote').find('.form-action').find('.btn');

        stepAct.click(function() {
            $('html, body').animate({
                scrollTop: $('.get-quote').offset().top
            }, 400);
        })

        stepAct.on('shown.bs.tab', function(e) {
            function stepWizz() {
                step.eq(n).addClass('active');
                step.eq(n).removeClass('pass');
                step.not(':eq(' + n + ')').removeClass('active');
                for (var i = 0; i < n; i++) {
                    step.eq(i).addClass('pass');
                }
            }
            if ($('#getQuote1').hasClass('active')) {
                n = 0;
                stepWizz();
            }
            if ($('#getQuote2').hasClass('active')) {
                n = 1;
                stepWizz();
            }
            if ($('#getQuote3').hasClass('active')) {
                n = 2;
                stepWizz();
            }
            if ($('#getQuote4').hasClass('active')) {
                n = 3;
                stepWizz();
            }
            if ($('#getQuote5').hasClass('active')) {
                n = 4;
                stepWizz();
            }
        })

    });

    $('.form-get-quote').each(function() {

        // var validator = $('form').validate({
        //     ignore: ":disabled, :hidden",
        //     errorPlacement: function(error, element) {
        //         var fg = $(element).closest('.valthis');
        //         error.appendTo(fg);
        //     }
        // });
        //
        // $('button:submit').click(function(e) {
        //     e.preventDefault();
        //     var t = $(this),
        //         tabPane = t.closest('.tab-pane'),
        //         input = tabPane.find(":input"),
        //         valid = true;
        //
        //     input.each(function() {
        //         if (!validator.element(this) && valid) {
        //             valid = false;
        //             //FALSE FOR ACTIVE VALIDATION
        //         }
        //     });
        //
        //     // if (valid) {
        //     //   t.tab("show");
        //     // }
        //     toTopGetQuote();
        // });

        function toTopGetQuote() {
            $('html, body').animate({
                scrollTop: $('.get-quote').offset().top
            }, 400);
        }

        // CONDITIONS

        $('#getQuote1').each(function() {

            //ON STEP 1
            $('#service_type').dependsOn({
                'input[name="type_of_trade"]': {
                    values: ['domestic']
                }
            }, {
                onDisable: function() {
                    $('#service_type').find('select').attr('disabled', 'disabled');
                    $('select.select').selectpicker('refresh');
                },
                onEnable: function() {
                    $('#service_type').find('select').prop("disabled", false);
                    $('select.select').selectpicker('refresh');
                }
            });

            $('#type_of_shipment').dependsOn({
                'input[name="type_of_transportation"]': {
                    values: ['ocean']
                }
            }, {
                onDisable: function() {
                    $('#type_of_shipment').find('input').attr('disabled', 'disabled');
                },
                onEnable: function() {
                    $('#type_of_shipment').find('input').prop("disabled", false);
                }
            });

            $('#incoterms').dependsOn({
                'input[name="type_of_trade"]': {
                    values: ['export', 'import']
                }
            }, {
                onDisable: function() {
                    $('#incoterms').find('select').attr('disabled', 'disabled');
                    $('select.select').selectpicker('refresh');
                },
                onEnable: function() {
                    $('#incoterms').find('select').prop("disabled", false);
                    $('select.select').selectpicker('refresh');
                }
            });

        });

        var totrade = '';
        var totrans = '';

        function cekForInconterms() {
            var incoterms = $('#incoterms').find('select'),
                opt = '<option ',
                opts = '<option selected ',
                opt2 = '</option>',
                exw = opt + 'value="EXW">EXW' + opt2,
                fca = opt + 'value="FCA" >FCA' + opt2,
                fas = opt + 'value="FAS" >FAS' + opt2,
                fob = opt + 'value="FOB" >FOB' + opt2,
                cfr = opt + 'value="CFR" >CFR' + opt2,
                cif = opt + 'value="CIF" >CIF' + opt2,
                cpt = opt + 'value="CPT" >CPT' + opt2,
                cip = opt + 'value="CIP" >CIP' + opt2,
                dat = opt + 'value="DAT" >DAT' + opt2,
                dap = opt + 'value="DAP" >DAP' + opt2,
                ddp = opt + 'value="DDP" >DDP' + opt2;

            var selected = $('#incoterms').find('select').data('selected');
            if (selected != undefined) {
                if (selected == 'exw') exw = opts + 'value="EXW">EXW' + opt2;
                if (selected == 'fca') fca = opts + 'value="FCA">FCA' + opt2;
                if (selected == 'fas') fas = opts + 'value="FAS">FAS' + opt2;
                if (selected == 'fob') fob = opts + 'value="FOB">FOB' + opt2;
                if (selected == 'cfr') cfr = opts + 'value="CFR">CFR' + opt2;
                if (selected == 'cif') cif = opts + 'value="CIF">CIF' + opt2;
                if (selected == 'cpt') cpt = opts + 'value="CPT">CPT' + opt2;
                if (selected == 'cip') cip = opts + 'value="CIP">CIP' + opt2;
                if (selected == 'dat') dat = opts + 'value="DAT">DAT' + opt2;
                if (selected == 'dap') dap = opts + 'value="DAP">DAP' + opt2;
                if (selected == 'ddp') ddp = opts + 'value="DDP">DDP' + opt2;
            }

            if (totrade == 'export' && totrans == 'ocean') {
                incoterms.find('option').remove().end()
                    .append(fca + fas + fob + cfr + cif + dat + dap + ddp);
                $('select.select').selectpicker('refresh');
            }
            if (totrade == 'export' && totrans == 'air') {
                incoterms.find('option').remove().end()
                    .append(fca + cpt + cip + dat + dap + ddp);
                $('select.select').selectpicker('refresh');
            }
            if (totrade == 'import' && totrans == 'ocean') {
                incoterms.find('option').remove().end()
                    .append(exw + fca + fas + fob + cfr + cif + dat + dap);
                $('select.select').selectpicker('refresh');
            }
            if (totrade == 'import' && totrans == 'air') {
                incoterms.find('option').remove().end()
                    .append(exw + fca + cpt + cip + dat + dap);
                $('select.select').selectpicker('refresh');
            }
        }
        $('#type_of_trade').each(function() {
            var input = $(this).find('input'),
                val = $(this).find('.active').find('input').val();

            totrade = val;
            // console.log('Totrade = ' + totrade)
            input.change(function() {
                totrade = $(this).val();
                cekForInconterms();
                // console.log('totrade '+totrade)
            })
        });

        $('#type_of_transportation').each(function() {
            var input = $(this).find('input'),
                val = $(this).find('.active').find('input').val();

            totrans = val;
            // console.log('Totrans = ' + totrans)
            input.change(function() {
                totrans = $(this).val();
                cekForInconterms();
                // console.log('totrans '+totrans)
            })
        });
        cekForInconterms();


        $('#getQuote3').each(function() {

            $('#shipment_value').dependsOn({
                'input[name="insurance"]': {
                    checked: true
                }
            }, {
                hide: false,
                onDisable: function() {
                    $('#shipment_value').find('select').attr('disabled', 'disabled');
                    $('#shipment_value').find('input').attr('disabled', 'disabled');
                    $('select.select').selectpicker('refresh');
                },
                onEnable: function() {
                    $('#shipment_value').find('select').prop("disabled", false);
                    $('#shipment_value').find('input').prop("disabled", false);
                    $('select.select').selectpicker('refresh');
                }
            });
            $('input[name="shipment_value"]').dependsOn({
                'input[name="insurance"]': {
                    checked: true
                }
            }, {
                hide: false,
            });

        });

    });

    $('.form-action').each(function() {
        var check = $('input[name="agree"]');
        if (check.length != 0) {
            $('button[type="submit"]').dependsOn({
                'input[name="agree"]': {
                    checked: true
                }
            }, {
                hide: false,
            });
        }
    });


    //OFFICE
    $('.office').each(function() {
        var t = $(this),
            add = t.parent().find('.btn-add-branch'),
            del = t.find('.btn-del'),
            item = t.find('.office-item'),
            bc = t.find('#branchCount'),
            n = bc.val();

        function bind(obj) {
            $('html, body').animate({
                scrollTop: $('.office-item:last-child').offset().top - 100
            }, 500);
            $('select.select').each(function() {
                $(this).selectpicker({
                    style: 'select-control',
                    size: 5
                });
            });
            $('.office-item').matchHeight();
            $("body").find('.tcontact input').keypress(function(e) {
                if (e.which != 8 && e.which != 0 && e.which != 43 && (e.which < 48 || e.which > 57)) {
                    return false;
                }
            });
        }

        function reOrder() {
            var roTemp = 0;
            $("body").find('.office-item').each(function() {

                $(this).find('input:not(.bs-searchbox input)').each(function() {
                    var name = $(this).attr('name');
                    name = name.substring(0, name.indexOf('_'));
                    $(this).attr('name', name + '_' + roTemp);
                    $(this).attr('data-no', roTemp);
                });

                $(this).find('select').each(function() {
                    var name = $(this).attr('name');
                    name = name.substring(0, name.indexOf('_'));
                    $(this).attr('name', name + '_' + roTemp);
                });

                $(this).find('select').each(function() {
                    // var data = $(this).attr('data-no');
                    // name = name.substring(0, name.indexOf('_'));
                    $(this).attr('data-no', roTemp);
                });

                $(this).find('textarea').each(function() {
                    var name = $(this).attr('name');
                    name = name.substring(0, name.indexOf('_'));
                    $(this).attr('name', name + '_' + roTemp);
                });

                roTemp++;
                // console.log('reorder');
            })
        }

        add.click(function() {
            n++;
            $('select.select').selectpicker('destroy');

            var format = item.eq(0).clone();
            html = format.clone();

            html.find('.title').each(function() {
                $(this).text('Branch Office');
            });
            html.find('input').each(function() {
                $(this).val('');
                $(this).attr('value', '');
            });
            html.find('textarea').each(function() {
                $(this).val('');
                $(this).attr('value', '');
            });
            html.find('.nclone').each(function() {
                $(this).remove();
            });
            html.prepend('<button class="btn-del">delete</button>');

            t.append(html.fadeIn());

            scrollTop: $(window).scrollTop() + 100
            bind(html);
            bc.val(n);
            reOrder();
        })

        $("body").on('click', '.btn-del', function() {
            $(this).closest('.office-item').remove();
            n--;
            bc.val(n);
            reOrder();
        });

    })

    //CARGO
    $('.cargo-wrap').each(function() {
        var t = $(this),
            box = t.find('.box-cargo'),
            add = t.find('.add-cargo'),
            del = t.find('.del-cargo'),
            item = t.find('.cargo'),
            bc = t.find('#cargocount'),
            n = 1;

        function bind(obj) {
            $('select.select').each(function() {
                $(this).selectpicker({
                    style: 'select-control',
                    size: 5
                });
            });
            calculate();
        }

        function calculate() {

            $("body").find('.cargo').each(function() {
                var t = $(this),
                    weight = t.find('.weight'),
                    length = t.find('.length'),
                    width = t.find('.width'),
                    height = t.find('.height'),
                    qty = t.find('.qty'),
                    tWeight = t.find('.tWeight'),
                    tVolume = t.find('.tVolume');

                function totalWeight() {
                    var ton = weight.val() * qty.val();
                    ton = ton / 1000;
                    if (ton != 0) {
                        tWeight.val(ton);
                    } else {
                        tWeight.val('');
                    }
                }

                function totalVolume() {
                    var m3 = length.val() * width.val() * height.val() * qty.val();
                    m3 = m3 / 1000000;
                    if (m3 != 0) {
                        tVolume.val(m3);
                    } else {
                        tVolume.val('');
                    }
                }

                weight.keyup(function() {
                    totalWeight();
                })
                length.keyup(function() {
                    totalVolume();
                })
                width.keyup(function() {
                    totalVolume();
                })
                height.keyup(function() {
                    totalVolume();
                })
                qty.keyup(function() {
                    totalWeight();
                    totalVolume()
                })
            })

        }
        calculate();

        function reOrder() {
            var roTemp = 0;
            $("body").find('.cargo').each(function() {

                $(this).find('input').each(function() {
                    var name = $(this).attr('name');
                    name = name.substring(0, name.indexOf('_'));
                    $(this).attr('name', name + '_' + roTemp);
                });
                $(this).find('select').each(function() {
                    var name = $(this).attr('name');
                    name = name.substring(0, name.indexOf('_'));
                    $(this).attr('name', name + '_' + roTemp);
                });
                $(this).find('textarea').each(function() {
                    var name = $(this).attr('name');
                    name = name.substring(0, name.indexOf('_'));
                    $(this).attr('name', name + '_' + roTemp);
                });

                roTemp++;
                // console.log('reorder');
            })
        }

        function activeDel() {
            $('body').find('.del-cargo').addClass('active');
        }

        add.click(function() {
            n++;
            $('select.select').selectpicker('destroy');

            var format = item.eq(0).clone(),
            html = format.clone();

            html.find('input').each(function() {
                $(this).val('');
                $(this).attr('value', '');
            });
            html.find('textarea').each(function() {
                $(this).val('');
                $(this).attr('value', '');
            });

            html.prepend('<button class="del-cargo"><i class="fa fa-trash-o" aria-hidden="true"></i> delete</button>');
            html.find('textarea').val('');
            box.append(html.fadeIn());
            activeDel();

            bind(html);
            bc.val(n);
            reOrder();
        })

        $("body").on('click', '.del-cargo', function() {
            $(this).closest('.cargo').remove();
            n--;
            bc.val(n);
            reOrder();
        });

    })

    $('.work-list').each(function() {
        $("body").on('click', '.del-btn', function() {
            $(this).closest('.item').remove();
        });
    })

    $('.dtpckr').each(function() {
        var date = new Date(),
            today = new Date(date.getFullYear(), date.getMonth(), date.getDate())

        $(this).datepicker({
            // daysOfWeekDisabled: [0],
            format: 'dd/mm/yyyy',
            autoclose: true,
            startDate: today
        });
    })

    $('.dtpckr2').each(function() {
        $(this).datepicker({
            // daysOfWeekDisabled: [0],
            format: 'dd/mm/yyyy',
            autoclose: true,
        });
    })


    $('form').each(function() {
        var submit = $(this).find('.btn-primary');
        submit.click(function(e) {
            // e.preventDefault();
            $('html, body').animate({
                scrollTop: $('form').offset().top - 100
            }, 300);
            $('.modal').animate({
                scrollTop: 0
            }, 300);
        });
    });

    $('#frmaddwhouse').each(function() {
        var submit = $(this).find('.btn-primary');
        submit.click(function(e) {
            $('html, body').animate({
                scrollTop: $('form').offset().top - 100
            }, 300)
        });
    });


    // $('.form-login').each(function() {
    //     var t = $(this),
    //         b = t.find('button:submit');
    //
    //     b.click(function() {
    //         $('.right').animate({
    //             scrollTop: 0
    //         }, 300);
    //         return false;
    //     });
    // })
    $(".num-only").keypress(function(e) {
        if (e.which != 8 && e.which != 0 && e.which != 43 && (e.which < 48 || e.which > 57)) {
            return false;
        }
    });


    $("body").find('.tcontact input').keypress(function(e) {
        if (e.which != 8 && e.which != 0 && e.which != 43 && (e.which < 48 || e.which > 57)) {
            return false;
        }
    });


    $('.upgrade').each(function() {
        var t = $(this),
            button = t.find('.confirm-again'),
            chkBox = t.find('.check-confirm-again');

        // button.addClass('disabled');
        chkBox.change(function() {
            button.toggleClass('disabled', !this.checked)
        }).change();
    })


    $('.work-h').each(function() {
        $('button[type="submit"]').click(function() {
            $('select.select').selectpicker('refresh');
        })

        var ns = $(this).find('input[name="nonstop"]');
        if (ns.length != 0) {
            // console.log('as')
            $('select[name="startH"]').dependsOn({
                'input[name="nonstop"]': {
                    checked: false
                }
            }, {
                hide: false,
                onDisable: function() {
                    $('select.select').selectpicker('refresh');
                },
                onEnable: function() {
                    $('select.select').selectpicker('refresh');
                }
            });
            $('select[name="endH"]').dependsOn({
                'input[name="nonstop"]': {
                    checked: false
                }
            }, {
                hide: false,
                onDisable: function() {
                    $('select.select').selectpicker('refresh');
                },
                onEnable: function() {
                    $('select.select').selectpicker('refresh');
                }
            });
        }
    })


    $('body').on('click', function(e) {
        $('[data-toggle="popover"]').each(function() {
            //the 'is' for buttons that trigger popups
            //the 'has' for icons within a button that triggers a popup
            if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
                $(this).popover('hide');
            }
        });
    });

    $('.input-tag').each(function() {
        var t = $(this),
            fg = $(this).closest('.form-group'),
            bt = fg.find('.box-tag'),
            tg = fg.find('span.tag'),
            max = $(this).data('max-options'),
            n = tg.length,
            dv = $(this).data('val');
        // console.log("tags: "+n);

        if (max == undefined) {
            max = 1000;
        }

        function updateVal() {
            var v1 = $('body').find('.input-tag').closest('.form-group').find('.tag').eq(0).data('value');
            var v2 = $('body').find('.input-tag').closest('.form-group').find('.tag').eq(1).data('value');
            var v3 = $('body').find('.input-tag').closest('.form-group').find('.tag').eq(2).data('value');
            var v4 = $('body').find('.input-tag').closest('.form-group').find('.tag').eq(3).data('value');
            var v5 = $('body').find('.input-tag').closest('.form-group').find('.tag').eq(4).data('value');
            var val = v1 + ',' + v2 + ',' + v3 + ',' + v4 + ',' + v5;
            var val2 = val.replace(/\,undefined/g, '');
            // val2 = val2==undifined?"":val2;
            t.attr('data-val', val2);
            if (val2 == 'undefined') {
                t.attr('data-val', '')
            }
            // console.log(val2);
        }
        $(this).keypress(function(e) {
            // var keyword = $('#keyword').val();

            if (e.which == 44) {
                e.preventDefault();
                if ($(this).val() != "" && n < max) {
                    var val = $(this).val();
                    bt.append('<span class="tag" data-value="' + val + '">' + val + '<i class="remove" data-toggle="tooltip" data-placement="top" title="" data-original-title="Remove">×</i></span>');
                    $(this).val("");
                    n++;
                    updateVal();
                }
            }
        });

        $('body').on('click', '.tag .remove', function() {
            n--;
            updateVal();
        });
    });

    $.fn.rupiah = function() {
        return this.each(function() {
            var v = $(this).val().split('.').join(''),
                f = v.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");

            $(this).val(f);
        })
    }

    $('.currency').each(function() {
        $(this).keyup(function() {
            $(this).rupiah();
        })
    });


    $('.form-login').each(function(){
        var t = $(this);

        $('#i_am_a').find('input[type="radio"]').change(function(){
            if($('#iama2').is(':checked')) {
                // alert("it's checked");
                $('#company_name').prop('required', true);
            } else {
                // alert("it's unchecked");
                $('#company_name').prop('required', false);
            }
        });
    });

    setTimeout(function() {
        HloadItem();
    }, 5000);

    sliderMd();
    slidersSmall();
    setMH();
    stickyFooter();
    multiselect();
    viewQuote();
    multiplier();
    multiplierWorHours();

});// END OF document READY

function setProvider(){
    var t = $('#providerCheck'),
        checkboxes = t.find("input[type='checkbox']"),
        data = $('#dataCheck').val(),
        set  = t.find("input[name='"+data+"']");

    set.prop("checked", true);
    set.parents("li").addClass('showed');
    $('#modalWarning').modal('hide');
    $('#modalWarning').attr('data-name', "");

}

function cancelProvider(){
    var t = $('#providerCheck'),
        dn = $('#modalWarning').attr('data-name'),
        cb1 = t.find("input[type='checkbox']"),
        bt = $(".form-edit-profile"),
        cb2 = bt.find("input[type='checkbox']"),
        set  = t.find("input[name='"+dn+"']"),
        set2  = bt.find("input[name='"+dn+"']"),
        sp = set2.parent('.btn');

    if(dn != ""){
        // btn.removeClass("active");
        // set2.trigger( "click" );
        // sp.trigger("click");
        // set.prop("checked", false);
        // set.prop("checked", false);
        // $('#modalWarning').attr('data-name', "");
    }
}
$('#modalWarning').on('hidden.bs.modal', function (e) {
    var t = $('#providerCheck'),
        dn = $('#modalWarning').attr('data-name'),
        cb1 = t.find("input[type='checkbox']"),
        bt = $(".form-edit-profile"),
        cb2 = bt.find("input[type='checkbox']"),
        set  = t.find("input[name='"+dn+"']"),
        set2  = bt.find("input[name='"+dn+"']"),
        sp = set2.parent('.btn');

    if(dn != ""){
        // btn.removeClass("active");
        // set2.trigger( "click" );
        sp.trigger("click");
        set.prop("checked", false);
        set.prop("checked", false);
        $('#modalWarning').attr('data-name', "");
    }
});

$('#providerCheck').each(function(){
    var t = $(this);
    var checkboxes = t.find("input[type='checkbox']"),
        checkp = t.find("input[name='providers']"),
        checkw = t.find("input[name='warehouse']"),
        checkt = t.find("input[name='trucking']"),
        checkf = t.find("input[name='freigth_rate']"),
        err = t.find(".cb-error");

    checkboxes.each(function(){
        var cb = $(this);
        cb.click(function(e){
            var data = $(this).attr('name');

            if( cb.is(":checked") ) {
                // cb.parents("li").addClass('showed');
                e.preventDefault();
                e.stopPropagation();
                $('#modalWarning').modal('show');
                $('#dataCheck').val(data);
                if($('.form-edit-profile .btn-toggle').length > 0){
                    var bt = $('.form-edit-profile .btn-toggle'),
                        inpt = bt.find("input[name="+data+"]"),
                        inptp = inpt.parents('.form-group');
                    inpt.parent('.btn').addClass('active');
                    inptp.siblings().addClass('active');
                }
            } else {
                cb.parents("li").removeClass('showed');
                if($('.form-edit-profile .btn-toggle').length > 0){
                    var bt = $('.form-edit-profile .btn-toggle'),
                        inpt = bt.find("input[name="+data+"]"),
                        inptp = inpt.parents('.form-group');
                    inpt.parent('.btn').removeClass('active');
                    inptp.siblings().removeClass('active');
                }
            }

            if (checkt.is(":checked")) {
                $('#freightRate').fadeIn();
            }else{
                $('#freightRate').fadeOut();
            };
        })

        if (checkt.is(":checked")) {
            $('#freightRate').fadeIn();
        }else{
            $('#freightRate').fadeOut();
        };
    })
    // checkboxes.click(function() {
        // checkboxes.attr("required", !checkboxes.is(":checked"));
        // if (checkp.is(":checked")) {
        //     checkp.parents("li").addClass('showed');
        // }else{
        //      checkp.parents("li").removeClass('showed');
        // };
        // if (checkw.is(":checked")) {
        //     checkw.parents("li").addClass('showed');
        // }else{
        //      checkw.parents("li").removeClass('showed');
        // };
        // if (checkt.is(":checked")) {
        //     checkt.parents("li").addClass('showed');
        // }else{
        //      checkt.parents("li").removeClass('showed');
        // };
        // if (checkf.is(":checked")) {
        //     checkf.parents("li").addClass('showed');
        // }else{
        //      checkf.parents("li").removeClass('showed');
        // };
    // });
    checkboxes.change(function() {
        if (!checkp.is(":checked") && !checkw.is(":checked") && !checkt.is(":checked")) {
            err.fadeIn();
        }else{
            err.fadeOut();
        };
    })

})

function sliderGal() {
  // reference for main items
  var slider = $('.gall-big-img');
  // reference for thumbnail items
  var thumbnailSlider = $('.gallery-slider');
  //transition time in ms
  var duration = 500,
     w = $(window).width();
  if (w < 760) {
    var itmthumb = 5;
  }else{
    var itmthumb = 9;
  };

  thumbnailSlider.addClass('owl-carousel');
  slider.addClass('owl-carousel');

  // carousel function for main slider
  slider.owlCarousel({
   loop:false,
   navText: ["<i class='fa fa-angle-left'></i>","<i class='fa fa-angle-right'></i>"],
   nav:true,
   dots: false,
   items:1
  }).on('changed.owl.carousel', function (e) {
   //On change of main item to trigger thumbnail item
   thumbnailSlider.trigger('to.owl.carousel', [e.item.index, duration, true]);
  });

  // carousel function for thumbnail slider
  thumbnailSlider.owlCarousel({
   loop:false,
   center: true, //to display the thumbnail item in center
   items: itmthumb,
   dots: false,
   margin: 10,
  }).on('click', '.owl-item', function () {
   // On click of thumbnail items to trigger same main item
   slider.trigger('to.owl.carousel', [$(this).index(), duration, true]);
   $('.owl-item').removeClass('current');
   $(this).addClass('current');
  }).on('changed.owl.carousel', function (e) {
   // On change of thumbnail item to trigger main item
   slider.trigger('to.owl.carousel', [e.item.index, duration, true]);
  });
   $('.gallery-slider .owl-item').eq(0).addClass('current');


  //These two are navigation for main items
  $('.slider-left').click(function() {
   slider.trigger('next.owl.carousel', function (){
       $('.gallery-slider .owl-item.current').removeClass('current');
       $('.gallery-slider .owl-item').addClass('current');
   });
  });
  $('.slider-left').click(function() {
   slider.trigger('prev.owl.carousel');
   $('.gallery-slider .owl-item.current').removeClass('current');
   $('.gallery-slider .owl-item').addClass('current');
  });
}sliderGal();

$('#frmaddwhouse').each(function() {
  var form = $(this);
  form.validate({
     ignore: ":disabled, :hidden",
     errorPlacement: function(error, element) {
         var fg = $(element).closest('.form-group');
        error.insertAfter(fg);
     }
   });
 });

$('#fchpswd').each(function() {
  var form = $(this);
  form.validate({
     ignore: ":disabled, :hidden",
     errorPlacement: function(error, element) {
         var cb = $(element).closest('.checkbox');
        error.insertAfter(cb);
        error.addClass('cb-error');
     }
   });
 });


$('.box-location').each(function(){
  var t = $(this),
      L = t.find('.location'),
      more = t.find('.more');

  var count = L.length;
//   console.log(count);
  if(count > 3){
      for (var i = 3; i < count; i++) {
          L.eq(i).addClass('fade');
          more.show();
      }
  }
  more.click(function(){
      L.toggleClass('in');
      $(this).toggleClass('in');
  });
});

$('.bt_choice').each(function(){
    var t = $(this),
        r = t.find('.rb'),
        c = t.find('.excollapse');
    // r.change(function(){
    //     if($(this).is(':checked')) {
    //         c.addClass('show')
    //      }else{
    //          c.removeClass('show')
    //      }
    // })
    $('input[type=radio][name=cm]').change(function() {
        if (this.value == 'paypal') {
            c.removeClass('show')
        }
        else if (this.value == 'bank_transfer') {
            c.addClass('show')
        }
    });
})


function boxLocation(){
    $('.box-location').each(function(){
      var t = $(this),
          L = t.find('.location'),
          more = t.find('.more');

      var count = L.length;
      console.log(count);
      if(count > 3){
          for (var i = 3; i < count; i++) {
              L.eq(i).addClass('fade');
              more.show();
          }
      }
      more.click(function(){
          L.toggleClass('in');
          $(this).toggleClass('in');
      });
    });
};

function itemclick(elem){
    $(elem).toggleClass('show');
    $(elem).find('.collapse').collapse('toggle');
}

function itemclickH(elem){
    $(elem).closest('.item').toggleClass('show');
    $(elem).closest('.content').siblings().collapse('toggle');
}

// $('.freight-rate').each(function(){
//     var t = $(this),
//         item = t.find('.item');

//     item.click(function(){
//         $(this).toggleClass('show');
//         $(this).find('.collapse').collapse('toggle');
//     })

// })


// Show more description

// $('.box-wrap-text').each(function(){
//     var t = $(this),
//         h = t.outerHeight();

//     if(h > 140){
//         t.addClass('less');
//         t.append('<span class="s-more"><i>Show more</i><i>Show less</i></span>')
//     }
//     $('body').on('click', '.box-wrap-text .s-more', function() {
//        t.toggleClass('less');
//     });
// });

$('.box-wrap-text').each(function(){
    $(this).bind('mousewheel DOMMouseScroll', function(e) {
        var scrollTo = null;

        if(e.type == 'mousewheel') {
            scrollTo = (e.originalEvent.wheelDelta * -0.20);
        }else if(e.type = 'DOMMouseScroll') {
            scrollTo = (e.originalEvent.wheelDelta * -0.20);
        }
        if(scrollTo) {
            e.preventDefault();
            $(this).scrollTop(scrollTo + $(this).scrollTop());
        }
    });
});


$(".form-edit-profile .btn-toggle").each(function(){
    var t = $(this),
        b = t.find('.btn'),
        p = t.parents('.form-group');
    b.each(function(){
        var i = $(this).find('input:checkbox'),
            inm = i.attr('name'),
            pc = $('#providerCheck'),
            pci = pc.find('input[name='+inm+']'),
            pcl = pci.parents('li');
        if(inm == "providers"){
            $(this).find('input:checkbox').attr('data-indx', '0');
            $("#providerCheck > li:eq(0) input").attr('data-indx', '0');
        }else if(inm == "warehouse"){
            $(this).find('input:checkbox').attr('data-indx', '1');
            $("#providerCheck > li:eq(1) input").attr('data-indx', '1');
        }else if(inm == "trucking"){
            $(this).find('input:checkbox').attr('data-indx', '2');
            $("#providerCheck > li:eq(2) input").attr('data-indx', '2');
        }
        $(this).click(function(){
            var indx = i.attr('data-indx');
            var pcin = pc.find('input[type="checkbox"]');
            console.log(indx);
            setTimeout(function() {
                if(b.hasClass('active')){
                    p.siblings().addClass('active');
                    // pc.find('li:eq('+indx+') input:checkbox').prop('checked', true);
                    $("#providerCheck > li:eq("+indx+") input").prop('checked', true);
                    pcl.addClass('showed');
                    $('#modalWarning').modal('show');
                    $('#modalWarning').attr('data-name', inm);
                }else{
                    p.siblings().removeClass('active');
                    // pc.find('li:eq('+indx+') input:checkbox').prop('checked', false);
                    $("#providerCheck > li:eq("+indx+") input").prop('checked', false);
                    pcl.removeClass('showed');
                }
            }, 200);
        })
    })

})


$(".form-edit-profile .tab-custom").each(function(){
    var t = $(this),
        a = t.find('a[data-toggle="tab"]'),
        n = a.data('name');
    // $("#providerCheck > li:not(.showed)").hide();

    a.on('shown.bs.tab', function (e) {
        var target = $(e.target).data("name") // activated tab
        // alert(target);
        // $("#providerCheck > li").removeClass('showed');
        // $("#providerCheck > li input").prop('checked', false);
        // $("#providerCheck > li input[name="+target+"]").prop('checked', true);
        $("#providerCheck > li input[name="+target+"]").parents("li").addClass("showed").show();
        // $("#providerCheck > li:not(.showed)").hide();
    });

});

// Multiple Upload image
document.addEventListener("DOMContentLoaded", init, false);
  var AttachmentArray = [];
  var arrCounter = 0;
  var filesCounterAlertStatus = false;

  var ul = document.createElement('div');
  ul.className = ("thumb-Images");
  ul.id = "imgList";

  function init() {
        if($('#wh_gallery').length > 0){
            // console.log('run wh gallery');
            document.querySelector('#wh_gallery').addEventListener('change', handleFileSelect, false);
        }
  }

    function handleFileSelect(e) {
        if (!e.target.files) return;

        var files = e.target.files;

        for (var i = 0, f; f = files[i]; i++) {

            var fileReader = new FileReader();

            fileReader.onload = (function (readerEvt) {
                return function (e) {
                    RenderThumbnail(e, readerEvt);

                    FillAttachmentArray(e, readerEvt)
                };
            })(f);
            fileReader.readAsDataURL(f);
        }
        document.getElementById('wh_gallery').addEventListener('change', handleFileSelect, false);
    }

    //To check files count according to upload conditions
    function CheckFilesCount(AttachmentArray) {
        var len = 0;
        for (var i = 0; i < AttachmentArray.length; i++) {
            if (AttachmentArray[i] !== undefined) {
                len++;
            }
        }
        //To check the length does not exceed 10 files maximum
        if (len > 9) {
            return false;
        }
        else
        {
            return true;
        }
    }

    //Render attachments thumbnails.
    function RenderThumbnail(e, readerEvt)
    {
        var li = document.createElement('div');
        li.className = ("col-sm-4");
        ul.appendChild(li);
        li.innerHTML = ['<div class="box-img"> <a class="btn-del" href="#">Delete</a>' +
            '<a class="img" href="#" style="background-image: url('+ e.target.result +')" data-lity></a>'
           + '</div>'].join('');
        document.getElementById('Filelist').insertBefore(ul, null);
    }

$('.front-page').each(function () {
    var t = $(this),
        lr = t.find('.list-row'),
        lc = t.find('.list-col')

    lr.each(function(){
        $(this).addClass('l');
        $(this).find('.title-section').next().addClass('loading');
    })
    lc.each(function(){
        $(this).addClass('l');
        $(this).find('.title-section').next().addClass('loading');
    })
})

function HloadItem() {
    $('.list-col').each(function(){
        $(this).removeClass('l');
        $(this).find('.title-section').next().removeClass('loading');
    })
    $('.list-row').each(function(){
        $(this).removeClass('l');
        $(this).find('.title-section').next().removeClass('loading');
    })
};

$('.has-sub').each(function(){
    var t = $(this);

    if ($(window).width() < 1025) {
        t.find('> a').click(function(e){
            e.preventDefault();
        })
    }
});


$('.office').each(function () {
    var t = $(this),
        brnc = t.find(".branch");

    if(brnc.length > 1){
        $(this).addClass('collapse');
    }
    t.click(function () {
       $(this).toggleClass("in");
    })
});


$('.publish-rate').each(function () {
    var t = $(this),
        fc = t.find('.form-control');

    fc.each(function () {
        $(this).change(function () {
            fc.removeAttr("required");
        })
    })
})

// $(document).ready(function() {
//     var $window = $(window);
//     var $sidebar = $(".sidebar");
//     var $sidebarHeight = $sidebar.innerHeight();
//     var $footerOffsetTop = $(".to-contact").offset().top;
//     var $sidebarOffset = $sidebar.offset();

//     $window.scroll(function() {
//       if($window.scrollTop() > $sidebarOffset.top) {
//         $sidebar.addClass("fixed");
//       } else {
//         $sidebar.removeClass("fixed");
//       }
//       if($window.scrollTop() + $sidebarHeight > $footerOffsetTop) {
//         $sidebar.css({"top" : -($window.scrollTop() + $sidebarHeight - $footerOffsetTop)});
//       } else {
//         $sidebar.css({"top": "0",});
//       }
//     });


//   });
